.line1 {
  width: 98px;
  height: 34px;
  text-align: left;
  font: normal normal bold 28px/34px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}

.Container_box {
  width: 800px;
  text-align: left;
  height: fit-content;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.c_logo > h4 {
  margin-top: 20px;
}

.try {
  display: flex;
}

.imagenlogo {
  width: 98px;
  height: 34px;
  text-align: left;
  font: normal normal bold 28px/34px Lato;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

.c_logo {
  top: 293px;
  left: 409px;
  width: 50px;
  height: 50px;
  /* background: transparent url("img/image.png") 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.imagenlogo > p {
  top: 300px;
  left: 478px;
  width: 192px;
  height: 37px;
}

.GRID {
  margin-left: 40px;
  width: 392px;
  height: 26px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) /
    var(--unnamed-line-spacing-26) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000de);
  text-align: left;
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 1;
}

.payment_box {
  width: 800px;
  height: 470px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.payment_box2 {
  width: 800px;
  height: 369px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.payment_logo {
  flex-direction: row;

  width: 300px;
  height: 50px;
  display: flex;
  /* background: transparent url("images/core-component-image.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
  text-align: left;
}

#fla {
  top: 2039px;
  left: 662px;
  width: 148px;
  height: 42px;
  border: 2px solid var(--unnamed-color-4a8cff);
  border: 2px solid #4a8cff;
  background-color: white;
  color: black;
  border-radius: 2px;
  opacity: 1;
}

#fla-1 {
  top: 2039px;
  left: 874px;
  width: 264px;
  height: 42px;
  background: var(--unnamed-color-4a8cff) 0% 0% no-repeat padding-box;
  background: #4a8cff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  color: white;
  border-radius: 2px;
  opacity: 1;
}

.payment_box > p {
  width: 367px;
  height: 10px;
  margin-bottom: 60px;
  margin-left: 29px;
  font: normal normal normal 24px/37px Roboto;
  color: #000000de;
  opacity: 1;
}

.payment_box2 > p {
  width: 317px;
  height: 10px;
  margin-left: 29px;
  margin-bottom: 60px;
  font: normal normal normal 24px/37px Roboto;
  color: #000000de;
  opacity: 1;
}

.Components {
  background: #f1f9ff 0% 0% no-repeat padding-box;
}

.Components {
  padding-left: 1%;
}

.Mui-checked {
  color: green;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 1rem;
}

.MuiFormControl-root {
  flex: 0.7;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
  font-size: 18px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009688;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009688;
}

.MuiCheckbox-colorSecondary.Mui-checked + .Components {
  background-color: burlywood;
}

.Container_box {
  width: 100%;
}

.payment_box {
  width: 100%;
  height: 330px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 2%;
}

.Container_box {
  width: 100%;
  padding: 2%;
}

.payment_box2 {
  width: 100%;
  height: 369px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.Components {
  padding-left: 1%;
  font-size: 18px;
}
