.link {
  top: 2296px;
  left: 386px;
  width: 319px;
  height: 26px;
  font: var(--unnamed-font-style-normal) normal medium
    var(--unnamed-font-size-20) / var(--unnamed-line-spacing-26)
    var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal medium 20px/26px Roboto;
  letter-spacing: 0px;
  color: #007bc3;
  opacity: 1;
}
.address {
  top: 2370px;
  left: 361px;
  width: 1059px;
  height: 233px;
  margin-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.currency {
  top: 2862px;
  left: 361px;
  width: 1059px;
  height: 131px;
  margin-top: 10px;
  margin-left: 62px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.name {
  top: 2370px;
  left: 361px;
  width: 1059px;
  height: 170px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.accom {
  top: 2370px;
  left: 361px;
  width: 1059px;
  height: 203px;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.contact {
  top: 2370px;
  left: 361px;
  width: 1059px;
  height: 263px;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
