.efg {
  width: 553px;
  height: 200px;
  margin-right: 100px;
  text-align: center;
  font: normal normal normal 26px/44px Lato;
  letter-spacing: 0.26px;
  color: #424242;
  opacity: 1;
  padding: 1%;
}
.ui {
  margin-left: 5px;
  text-align: left;
}
