.card {
    width: 600px;
    height: 93px;
    display: flex;
    margin-left: 16%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px white;
    border-radius: 4px;
    opacity: 1;
    justify-content: space-between;
    font: normal normal bold 20px/24px Lato;
    letter-spacing: 0px;
    color: #000000de;
    opacity: 1;
}

.line {
    width: 798px;
    height: 44px;
    text-align: center;
    font: normal normal bold 28px/34px Lato;
    letter-spacing: 0px;
    color: #7d7d7d;
    opacity: 1;
}

.border-primary {
    border-color: transparent;
}

.card {
    margin-bottom: 2%;
}

.row.w-90.m-0.border.border.my-2.py-2.px-0 {
    border: 0px;
}

div#root {
    background-color: #F8F8F8;
}

.Box_type {
    box-shadow: 0px 2px 2px #00000029;
}

.accommodation_quiz {
    display: flex;
}

.box_content {
    margin-left: 15px;
}

.accommodation_quiz {
    margin-right: auto;
}

.MuiBox-root.MuiBox-root-25.Box_type {
    border-radius: 4px;
}

.box_content {
    display: flex;
    align-items: center;
}