.headings {
  top: 209px;
  width: 802px;
  height: 34px;
  align-items: left;
  font: normal normal bold 28px/34px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}

.questionare {
  padding-left: 1.5%;
}

.whitebox {
  background-color: white;
  padding: 1%;
  height: 220px;
}

.medbox {
  padding: 1%;
  background-color: white;
  text-align: center;
}
.medbox > h5 {
  text-align: right;
}
.medbox > p {
  font-size: 18px;
}

.Mainss {
  height: 900px;
}

.headings > p {
  text-align: left;
  display: flex;
  align-items: left;
}

#field {
  margin-top: 24px;
  height: 50px;
  width: 200px;
  margin: auto;
}

.page {
  margin-top: 24px;
  margin-left: 24px;
  font-size: 13px;
  height: 300px;
  flex-direction: column;
  /* display: flex; */
  justify-content: space-between;
}

.page > h3 {
  text-align: left;
}

.page > p {
  text-align: left;
  margin-top: 24px;
  font-size: 15px;
  /* display: flex; */
}

.first {
  top: 363px;
  margin-left: 24px;
  width: 400px;
  height: 67px;
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

#b1 {
  top: 1472px;
  left: 1444px;
  width: 140px;
  height: 39px;
  background-color: #4a8cff;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: white;
  font: normal normal medium 14px/19px Robot;
}

.first > p {
  letter-spacing: var(--unnamed-character-spacing-0);
  font-size: 19px;
  text-align: left;
  font: normal normal medium 28px/37px Roboto;
  letter-spacing: 0px;
  font-weight: bold;
  color: #000000de;
  opacity: 0.5;
}

.Compo {
  top: 714px;
  left: 361px;
  width: 800px;
  height: 494px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

/* .box_1 {
  margin-top: 18px;
  left: 372px;
  width: px;
  height: 177px;
  background: #f1f9ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
} */

.box_1 > p {
  top: 728px;
  left: 386px;
  width: 109px;
  height: 37px;
  text-align: left;
  font: normal normal medium 28px/37px Roboto;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 0.5;
}

#time {
  top: 784px;
  width: 170px;
  height: 24px;
  text-align: left;
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.field {
  display: flex;
  margin-top: 10px;
  width: 209px;
  height: 42px;
  align-items: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}

.Mainss {
  top: 714px;
  left: 361px;
  width: 800px;
  height: 542px;
  margin: auto;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.Mainss > p {
  margin-left: 10px;
  text-align: left;
}

.Compo > p {
  margin-left: 10px;
  text-align: left;
}

.Check {
  top: 728px;
  left: 372px;
  width: 800px;
  margin-top: 10px;
  margin: auto;
  height: 37px;
  background: #f1f9ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.Check > p {
  margin-top: 10px;
  margin: auto;
  font-size: 19px;
  text-align: left;
  background: #f1f9ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.Check > p {
  font-size: 19px;
  text-align: left;
  margin: auto;
  margin-top: 20px;
  background: #f1f9ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.Radio {
  width: 1400px;
  height: 20px;
  margin-bottom: 40px;
}

.policy {
  top: 1429px;
  left: 361px;
  width: 800px;
  height: 100px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.image_1 {
  top: 1452px;
  left: 405px;
  width: 50px;
  height: 50px;
  /* background: transparent url("images/logo/image.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
}

.image_1 > p {
  width: 500px;
  height: 10px;
  text-align: center;
  font: normal normal normal 21px/37px Roboto;
  color: #000000de;
  opacity: 1;
}

.rows {
  width: 800px;
  height: 10px;
  color: black;
  margin-bottom: 10px;
}

.rows > button {
  color: white;
  background-color: blue;
}

.notes {
  width: 800px;
  height: 121px;
  margin: auto;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.notes_like {
  width: 800px;
  height: 381px;
  display: flex;
  flex-direction: column;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.notes_like > p {
  font-size: 19px;
  text-align: left;
}

.notes > p {
  margin-left: 2px;
  margin: auto;
  width: 800px;
  font-size: 19px;
  text-align: left;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.ending_button {
  top: 1918px;
  left: 662px;
  width: 300px;
  height: 82px;
  margin-left: 300px;
  opacity: 1;
}
#ab {
  text-align: left;
}
.headings > p {
  text-align: center;
  margin-left: 242px;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  padding: 1%;
  height: 40px;
  width: 800px;
}

.images_logo {
  top: 279px;
  left: 750px;
  width: 72px;
  height: 66px;
  /* background: transparent url("images/logo/core-component-image.png") 0% 0%
    no-repeat padding-box; */
  opacity: 1;
}

.formControl {
  margin: auto;
}

select.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input {
  background-color: white;
  border-color: black;
  border: 1px;
}

.Main_box {
  padding: 1%;
  background-color: white;
}

.Ct {
  padding-left: 0.9%;
}

.Mainss {
  padding: 1.5%;
  background-color: white;
}

.box_1 {
  padding-left: 1.4%;
  background: #f1f9ff 0% 0% no-repeat padding-box;
}

#btn-end {
  width: 204px;
  height: 42px;
  background: var(--unnamed-color-4a8cff) 0% 0% no-repeat padding-box;
  background: #4a8cff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  color: white;
  border-radius: 2px;
  opacity: 1;
}

.forms > p {
  top: 371px;
  left: 386px;
  width: 157px;
  height: 37px;
  font-weight: bold;
  text-align: left;
  font-size: 19px;
  font: normal normal medium 28px/37px Roboto;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 0.5;
  text-align: left;
}

.formControl > h6 {
  font-weight: bold;
  margin: auto;
  font-size: 19px;
  font: normal normal medium 28px/37px Roboto;
  color: #000000de;
  opacity: 0.5;
}

.multi_box {
  top: 480px;
  left: 386px;
  width: 649px;
  height: 143px;
  margin: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 1px;
  opacity: 1;
}

#test {
  margin: auto;
}

.notes_like {
  width: 100%;
  height: 221px;
  position: relative;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.Mainss {
  top: 714px;
  left: 361px;
  width: 100%;
  height: 542px;
  margin: 0;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 1%;
  margin-right: 1%;
}
