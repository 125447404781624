.DOC {
  width: 100%;
  padding: 10px;
  text-align: left;
  font: normal normal bold 28px/34px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}
.inner_cont {
  top: 273px;
  left: 361px;
  width: 800px;
  height: 100px;

  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.imr_logo {
  top: 273px;
  margin-top: 20px;
  margin-left: 20px;
  width: 65px;
  height: 50px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.im_logo {
  width: 160px;
  height: 30px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
#bfa {
  margin-left: 10px;
  width: 115px;
  height: 36px;
  background: #2a99fb 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 2px;
  color: white;
  opacity: 1;
}
.logo_ima {
  top: 438px;
  margin-left: 191px;
  width: 36px;
  height: 36px;
  /* background: transparent url('img/image.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
}
