.but {
  border: 2px solid #4a8cff;
  background-color: #4a8cff;
  color: white;
  border-radius: 2px;
  opacity: 1;
  padding: 0.4%;
}
.left {
  margin-top: 8px;
  font-size: 11px;
  justify-content: space-between;
  padding: 1%;
  width: 280px;
}

.fg2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 700px;
  text-align: center;
}
.state {
  text-align: center;
}
