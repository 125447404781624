.one_che {
    margin-top: 0px;
    width: 100%;
    height: 100vh;
    position: relative;
    background-size: cover;
    background: transparent url("Cheethas\ in\ rain_DSC07787.png")
       no-repeat center center fixed;
    opacity: 1;
  }
  .sidelay {
    /* margin-top: 50px; */
    margin-left: auto;
    margin-right: 20px;
    width: 418px;
    height: 450px;
    position: relative;
    /* background: #000000 0% 0% no-repeat padding-box; */
    /* border: 1px solid #707070; */
    border-radius: 8px;
    /* opacity: 0.13; */
  }
  
  .sidelay-bg{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.13;
  }
  
  .sidelay-content{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  /* .sidelay > h1 {
    opacity: 1;
    color: white;
    font-weight: bold;
  }
  .sidelay > p {
    opacity: 1;
    color: white;
  } */
  
  .regis {
    margin-top: 78px;
    margin-left: 78px;
    width: 266px;
    height: 50px;
    background: #1c8e4d 100% 100% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .p-div {
    margin-top: 15vh;
  }

  @media only screen and (max-width: 650px ) {
    .p-div {
      margin-top: 40vh;
    }
    
  }

  @media only screen and (max-width: 770px ) {
    .p-div {
      margin-top: 20vh;
    }
    
  }

 
  