body {
  background-color: #f5f5f5;
}

h4 {
  color: "#7D7D7D";
}

.Main {
  padding: 1.5%;
  background-color: white;
}

.Components {
  background: #f1f9ff 0% 0% no-repeat padding-box;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 1rem;
}

.Components {
  padding-left: 1.5%;
}

.Mui-checked {
  color: green;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  padding: 2%;
}

select.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input {
  background-color: white;
  border-color: black;
  border: 1px;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 0px solid black;
  pointer-events: none;
}

.MuiFormControl-root {
  flex: 0.7;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
  font-size: 18px;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 0px solid black;
  pointer-events: none;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  padding: 0.5%;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009688;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009688;
}

.MuiCheckbox-colorSecondary.Mui-checked + .Components {
  background-color: burlywood;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  padding: 2%;
  height: 70px;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  height: 60px;
}

.media {
  display: flex;
}

.media > .media_content > p {
  font-size: 13px;
}

.button_media > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #2a99fb;
}

.button_media > span.MuiButton-label {
  background-color: #2a99fb;
  color: white;
}

.media {
  display: flex;
  justify-content: space-between;
}

.media > .media_content > p {
  font-size: 15px;
  color: #565656;
}

.media > span.MuiButton-label {
  color: white;
}

.Main.image {
  display: block;
  flex-direction: row;
}

.imageButton {
  width: 25%;
  padding: 8%;
  background-color: #bce0fd;
}

.imageButton > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  padding-left: 2px;
  padding-right: 2px;
}

.imageButton > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  padding-left: 2px;
  width: 100px;
  padding-right: 2px;
}

@media all and (max-width: 900px) {
  .imageButton {
    width: 35%;
    padding: 10%;
    background-color: #bce0fd;
  }
}

.button_media.videoButton {
  padding: 3%;
  background-color: #bce0fd;
}

.media_content_video {
  display: flex;
  flex-direction: row;
}

.media_content_video {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  max-width: 30%;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  max-width: 30%;
  margin-left: 14.5%;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  width: 130%;
}

.media_content_video > h2 {
  display: flex;
  align-items: center;
}

form.makeStyles-root-2 {
  padding-top: 6%;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  margin-top: 4%;
}

form.makeStyles-root-2 {
  padding-top: 6%;
  flex: 0.8;
}

form.makeStyles-root-2
  > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  margin-left: 15%;
  width: 20%;
  margin-top: 3%;
  background-color: #2a99fb;
  color: white;
}

.button_media > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #2a99fb;
  color: white;
}

form.makeStyles-root-10 {
  width: 350px;
}

.makeStyles-root-10 > * {
  width: 25ch;
  margin: 8px;
  max-width: 150px;
}

form.makeStyles-root-10
  > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  margin-left: 14%;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  width: 300px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #2a99fb;
  color: white;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  width: 210px;
}

form.makeStyles-root-13 {
  display: grid;
  width: 300px;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  width: 240px;
}

.Radio {
  display: flex;
  background-color: #f1f9ff;
  justify-content: space-between;
  padding: 1%;
  height: 100%;
  width: 100%;
}

.MuiFormGroup-root {
  display: flex;
  flex-direction: row;
}

div#pricebar {
  display: flex;
  margin-top: 4%;
}

.Main_dropdown {
  background-color: #f1f9ff;
}
