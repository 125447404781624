.TourReg_basic > h3 {
  display: flex;
  color: #949494;
}

.TourReg_basic {
  padding: 4%;
}

.TourReg_basic > i {
  display: flex;
  color: #848484;
  padding-left: 1%;
  font-size: 12px;
  background-color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}

.company_ {
  display: flex;
  flex-direction: column;
}

.basic_company {
  display: flex;
}

.TourReg_basic {
  padding: 4%;
  width: 100%;
}

.basic_company {
  display: block;
}

.company_name {
  display: flex;
  flex-direction: column;
}

.TourReg_basic > p {
  display: flex;
}

.company_year_number {
  display: flex;
}

form.company_year {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

p {
  display: flex;
}

.TourReg_basic {
  padding: 10%;
  padding-top: 4%;
  width: 100%;
}

.basic_company {
  display: block;
  background-color: white;
  padding: 4%;
}

.company_contact {
  display: block;
  background-color: white;
  padding: 4%;
}

.company_address {
  display: block;
  background-color: white;
  padding: 4%;
}

.Currency_picker
  > .MuiFormControl-root.MuiTextField-root
  > label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
  font-size: 14px;
}

.PhoneInput {
  display: flex;
  align-items: center;
  width: 50%;
}

.Tour_policies {
  padding: 10%;
  padding-top: 4%;
}

.Tour_policies {
  padding: 10%;
  padding-top: 4%;
}

.Tour_policies {
  padding: 10%;
  padding-top: 4%;
  width: 100%;
}

.Policies_document_main {
  display: flex;
  justify-content: space-between;
  width: 33% !important;
  align-items: center;
}

.Policies_document {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Policies_document {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Policies_button.MuiButton-containedPrimary {
  margin-top: 0%;
}

.Policies_document {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4%;
}

.Policies_document {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4%;
  background-color: white;
}

form.company_name.About {
  width: 120%;
}

.Tour_finance {
  padding: 11%;
  padding-top: 4%;
}

.finance_account_details {
  background-color: white;
}

.finance_account_details_header {
  display: flex;
}

.Tour_finance {
  padding: 11%;
  padding-top: 4%;
  width: 100%;
}

.finance_account_details {
  background-color: white;
  padding: 4%;
}

.finance_account_details_header {
  display: flex;
  align-items: center;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: black;
  font-size: 15px;
}

form.Tour_Finance_textfield {
  display: flex;
  flex-direction: column;
}

.finance_account_details_header > h3 {
  margin-left: 4%;
}

.Components {
  padding-left: 1%;
  font-size: 18px;
  display: flex;
}

@media all and (min-width: 720px) {
  form.Tour_Finance_textfield > .MuiFormControl-root > .MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    width: 70%;
  }
  .Policies_document_main {
    display: flex;
    justify-content: space-between;
    width: 54%;
    align-items: center;
  }
  form.company_year.seconds {
    margin-left: -15%;
  }
  .PhoneInput {
    display: flex;
    align-items: center;
    width: 70%;
  }
  .company_name > .MuiFormControl-root.MuiTextField-root {
    width: 79%;
  }
  .company_name
    > .MuiFormControl-root.MuiTextField-root
    > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 100%;
  }
  .company_year > .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
  .company_year
    > .MuiFormControl-root.MuiTextField-root
    > .MuiOutlinedInput-root {
    width: 60%;
  }
  form.company_year.second {
    margin-left: -9%;
  }
  .About
    > .MuiFormControl-root.MuiTextField-root
    > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 150px;
  }
}

@media all and (min-width: 1024px) {
  form.Tour_Finance_textfield.MuiFormControl-root > .MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    width: 70%;
  }
  form.company_year.seconds {
    margin-left: -15%;
  }
  .company_name > .MuiFormControl-root.MuiTextField-root {
    width: 50%;
  }
  .company_name
    > .MuiFormControl-root.MuiTextField-root
    > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 150%;
  }
  form.company_year.second {
    margin-left: -23%;
  }
  .About
    > .MuiFormControl-root.MuiTextField-root
    > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 150%;
    height: 200px;
  }
  .Policies_document_main {
    display: flex;
    width: 58%;
    align-items: center;
    justify-content: start;
  }
  .Policies_document_main > h6 {
    font-size: 1.6rem;
    margin-left: 5%;
  }
}

@media all and (max-width: 670px) {
  .Policies_document_main > h6 {
    font-size: 0.8rem;
  }
  .Policies_document_main {
    display: flex;
    justify-content: space-between;
    width: 58%;
    align-items: center;
  }
  .PhoneInput {
    display: flex;
    align-items: center;
    width: 90%;
  }
  .company_year_number {
    display: flex;
    flex-direction: column;
  }
  .About
    > .MuiFormControl-root.MuiTextField-root
    > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 150px;
  }
  form.company_year {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 2%;
  }
}

@media all and (max-width: 350px) {
  .MuiToolbar-root.MuiToolbar-regular.makeStyles-toolbar-5.MuiToolbar-gutters {
    padding: 0px;
  }
  .imgresp {
    width: 50px !important;
    height: 50px !important;
  }
}

.safari-input {
  outline: none !important;
  border: 1px solid #f1f9ff;
  background-color: "#F1F9FF";
  width: "100%";
}
.company_name .About textarea {
  height: 450px;
  width: 100%;
}
