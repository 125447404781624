.header{
    z-index: 999;
    position: fixed;
    background-color: white;
    width: 100%;
    height:70px;
}

.pointer {
    cursor: pointer;
}