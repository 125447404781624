@media screen and (max-width: 1100px) {
  .widthmediaquery {
    width: 100% !important;
  }
}
@media screen and (max-width: 1400px) {
  .widthmediaquery {
    width: 75% !important;
  }
}

@media screen and (max-width: 600px) {
  .wordsmediaquery {
    font-size: xx-small;
    line-height: normal;
  }
}
@media screen and (max-width: 800px) {
  .wordsmediaquery {
    font-size: x-small;
    line-height: normal;
  }
}

@media screen and (max-width: 600px) {
  .imgmedia {
    width: 350px !important;
    height: 350px !important;
  }
  .namemedia {
    font-size: x-small;
  }
  .titelname {
    font-size: medium;
  }
}
