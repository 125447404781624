.Home {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .Home-page {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .right-panel {
    width: 100% !important;
    margin-left: 10px !important;
    padding: 50px 30px;
  }
}

@media screen and (min-width: 1000px) {
  .Home-page {
    width: 40%;
  }
}
@media screen and (min-width: 600px) {
  .Home-page {
    width: 45%;
  }
}

.Home-page {
  float: left;
  height: 9rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000029;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.Home-heading {
  text-align: left;
  font: normal normal medium 28px/37px Roboto;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 1;
}
.Home-heading {
  text-align: left;
  font: normal normal 600 18px/22px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}
.Home-btn {
  height: 2.25rem;
  float: right;
  margin-right: 5%;
  background: #4a8cff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 2px;
  opacity: 1;
  font: normal normal medium 14px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
}
.left-panel {
  overflow: hidden;
  text-align: center;
  width: 200px;
  padding-top: 0px;
  padding-left: 0px;
  height: 100%;
  z-index: 999;
  position: fixed;
  top: 70px;
  /* background-color: rgba(24,18,15,0.7); */
  background-color: rgba(0, 0, 0, 0.25);
}

.right-panel {
  padding-top: 80px;
  position: relative;
  width: calc(100% - 200px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 50px;
  min-height: 101vh;
  background-attachment: fixed;
  margin-left: 200px;
}

.tour-name {
  padding: 2%;
  padding-left: 3%;
}
