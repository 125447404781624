.heading {
  top: 213px;
  left: 748px;
  width: 424px;
  height: 34px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-28) / var(--unnamed-line-spacing-34)
    var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);

  text-align: center;
  font: normal normal bold 28px/34px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}
.heading > p {
  width: 428px;
  height: 32px;
  margin-left: 400px;
  letter-spacing: var(--unnamed-character-spacing-0);

  font: normal normal bold 25px/34px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
}
.center {
  margin-top: 180px;
  width: 500px;
  height: 96px;
  margin-left: 400px;
  border: 2px solid var(--unnamed-color-4a8cff);
  border: 2px solid #4a8cff;
  border-radius: 3px;
  opacity: 1;
}
.center > p {
  text-align: center;
  font: outline;
  margin: auto;
  color: #4a8cff;
  font-size: 32px;
}
.Main {
  padding: 1.5%;
  background-color: white;
}

.Components {
  background: #f1f9ff 0% 0% no-repeat padding-box;
}
.accom {
  padding: 1%;
  top: 2370px;
  left: 361px;
  width: 1110px;
  height: 400px;
  margin-top: 15px;
  margin-left: 90px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.firstbox {
  top: 1327px;
  left: 386px;
  margin-left: 20px;
  width: 339px;
  height: 99px;
  opacity: 1;
}
.firstbox > p {
  text-align: left;
  font-size: 16px;
  width: auto;
}
.ending {
  top: 1720px;
  left: 386px;
  width: 549px;
  height: 62px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 2px;
  opacity: 1;
}
.one {
  top: 1720px;
  left: 386px;
  width: 200px;
  height: 42px;
  background: #bce0fd 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 2px 0px 0px 2px;
  opacity: 1;

  margin-left: 15px;
}
.two {
  top: 1720px;
  left: 767px;
  width: 70px;
  height: 42px;
  background: var(---e4e7e8) 0% 0% no-repeat padding-box;
  background: #e4e7e8 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.two {
  top: 1720px;
  left: 767px;
  width: 70px;
  height: 42px;
  background: var(---e4e7e8) 0% 0% no-repeat padding-box;
  background: #e4e7e8 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.three {
  top: 1720px;
  left: 767px;
  width: 90px;
  height: 42px;
  background: var(---e4e7e8) 0% 0% no-repeat padding-box;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.currencybox {
  top: 2370px;
  left: 361px;
  width: 1090px;
  height: 203px;
  margin-top: 20px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.buttons {
  top: 2067px;
  left: 662px;
  width: 180px;
  height: 42px;
  margin-left: 400px;
  border: 2px solid #4a8cff;
  border-radius: 2px;
  opacity: 1;
}
.button {
  top: 2089px;
  left: 1014px;
  width: 104px;
  height: 29px;

  text-align: center;
  font: normal normal bold 24px/29px Lato;
  letter-spacing: 0px;
  background-color: #4a8cff;
  color: #ffffff;
  opacity: 1;
}
.buttons2 {
  top: 2067px;
  left: 662px;
  width: 180px;
  height: 42px;
  margin-left: 400px;
  border: 2px solid #4a8cff;
  border-radius: 2px;
  opacity: 1;
}
.buttons2 > button {
  top: 2067px;
  left: 662px;
  width: 180px;
  height: 42px;
  background-color: #4a8cff;
  color: wheat;
}
.label {
  top: 1462px;
  left: 386px;
  width: 137px;
  height: 26px;

  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000de);
  text-align: left;
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 1;
}
.box {
  top: 285px;
  left: 361px;
  width: 1199px;
  height: 336px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.h6 {
  text-align: left;
}
.right {
  margin-left: 700px;
  top: 356px;
  left: 856px;
  width: 225px;
  height: 20px;
  opacity: 1;
}
.right > p {
  top: 430px;
  left: 856px;
  width: 184px;
  height: 20px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 20px Source
    Sans Pro;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: center;
  font: normal normal normal 16px/20px Source Sans Pro;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.52;
}
.Components > p {
  top: 430px;
  left: 856px;
  width: 184px;
  height: 20px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 20px Source
    Sans Pro;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: center;
  font: normal normal normal 16px/20px Source Sans Pro;
  font-size: 24px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.52;
}
