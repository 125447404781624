.authCard {
  min-height: 450px;
  max-width: 400px;
  height: fit-content;
}

.profile-image-nav {
  width: 30px;
  height: 30px;
}

.cursor-pointer {
  cursor: pointer;
}
.PhoneInputInput {
  height: 50px;
  border-radius: 4px;
  padding-left: 15px;
}

.bg-form-blue {
  background-color: #f1f9ff;
}

.toolTip {
  position: relative;
  display: inline-block;
}

.toolTip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.toolTip:hover .tooltiptext {
  visibility: visible;
}

.steps-form-item {
  position: relative;
  width: 50px;
  height: 50px;
}
.steps-form-item-line-completed {
  width: 100%;
  position: absolute;
  left: -50%;
  top: 50%;
  z-index: 0;
  border-width: 100%;
  border-top: 5px solid blue;
}
.phoneStyle {
  font-family: Helvetica;
}
.PhoneInputCountry {
  padding-right: 8px;
  margin-left: 8px;
}
.steps-form-item-line {
  width: 100%;
  position: absolute;
  left: -50%;
  top: 50%;
  z-index: 0;
  border-width: 100%;
  border-top: 5px solid grey;
}
.steps-form-item-number-completed {
  width: 50px;
  position: absolute;
  left: 50%;
  margin: auto;
  z-index: 2;
  transform: translate(-50%);
  height: 50px;
  background-color: blue;
  border-radius: 50%;
}
.steps-form-item-number {
  position: absolute;
  left: 50%;
  width: 50px;
  margin: auto;
  height: 50px;
  z-index: 2;
  transform: translate(-50%);
  background-color: grey;
  border-radius: 50%;
}

.stepper-title {
  width: fit-content;
  margin: auto;
}
.accommodation-list-item {
  position: relative;
}

.accommodation-list-item-tag {
  position: absolute;
  left: 0;
  top: 10%;
  width: 199px;
  height: 55px;
  box-shadow: 0px 2px 2px #00000029;
  border-radius: 0px 28px 28px 0px;
  background-color: gray;
}

.accomodation-type-card {
  max-width: 600px;
  width: 100%;
}

.accommodation-home-title {
  font-size: 22px;
}
.accommodation-home-total-count {
  font-size: 15px;
  opacity: 0.56;
  margin-bottom: 1px;
}
.accommodation-home-address-text {
  font-size: 15px;
  margin-bottom: 1px;
}

.accommodation-type-font {
  font-weight: 600;
  display: flex;
  font-size: clamp(11px, 4vw, 22px);
}

.header-top-space {
  padding-top: 80px;
}

.containerrr {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.multiple-image {
  position: relative;
}

.multiple-image-del {
  position: absolute;
  background-color: #9a9a9a;
  border-radius: 50%;
  padding: 1px;
  font-size: 12px;
  left: 85%;
}

.App {
  text-align: center;
}
.phoneStyle {
  width: 280px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .phoneStyle {
    width: 280px !important;
  }
}
@media (max-width: 361px) {
  .phoneStyle {
    width: 230px !important;
  }
}
