/* .m {
  border: 1px solid var(---bdbdbd);
  background-color: #f0f0f7 100% 100% no-repeat padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  opacity: 1;
  top: 284px;
  -ms-transform-origin-x: 9%;

  height: 464px;
  width: 100px;
} */
/* .h1 {
  top: 398px;
  left: 928px;
  width: 65px;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-18) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: grey;
  text-align: left;
  font-family: normal normal bold 18px/22px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
} */

.image_logo {
  top: 318px;
  left: 823px;
  width: 324px;
  height: 80px;
  /* background: transparent url("../../../public/images/logo/Mask Group 4.png") 0% 0% no-repeat */
  /* padding-box; */
  opacity: 1;
}
.end {
  left: 639px;
  width: 179px;
  height: 19px;
  top: 398px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) / 24px
    var(--unnamed-font-family-lato);
  text-align: center;
  font: normal normal normal 14px/24px Lato;
  letter-spacing: 0.1px;
  color: #707070;
  opacity: 1;
}
.head {
  top: 398px;
  left: 822px;
  width: 85px;
  height: 24px;

  letter-spacing: var(--unnamed-character-spacing-0);

  text-align: center;
  font: normal normal bold 18px/22px Lato;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
  font-size: 14px;
  font-weight: 800;
}

.fg {
  top: 294px;
  left: 712px;
  width: 126px;
  height: 554px;
}
.fg > p {
  top: 294px;
  left: 712px;
  width: 126px;
  height: 554px;
}
.fg1 {
  top: 294px;
  left: 712px;
  width: 126px;
  height: 554px;
}
.con {
  top: 50%;
  left: 50%;
  max-width: 496px;
  height: 454px;

  background: transparent 0% 0% no-repeat padding-box 500px 500px;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  opacity: 1;
}
.fg2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 700px;
  text-align: center;
}
#bit {
  border: 2px solid #4a8cff;
  background-color: #4a8cff;
  color: white;
  border-radius: 2px;
  opacity: 1;
}
.maria {
  font-size: 13px;
  margin-top: 5px;
  justify-content: space-between;
  padding: 1%;
  width: 280px;
}
